module.exports = [{
      plugin: require('/home/vsts/work/1/s/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"G-G8XEVZ0HBX","head":true,"anonymize":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]},
    },{
      plugin: require('/home/vsts/work/1/s/node_modules/gatsby-plugin-prismic-preview/gatsby-browser'),
      options: {"plugins":[],"repositoryName":"sje-corp-site"},
    },{
      plugin: require('/home/vsts/work/1/s/node_modules/gatsby-plugin-lunr/gatsby-browser'),
      options: {"plugins":[],"languages":[{"name":"en"}],"fields":[{"name":"title","store":true,"attributes":{"boost":20}},{"name":"content","store":false},{"name":"display_content","store":true},{"name":"url","store":true},{"name":"id","store":true}],"resolvers":{"SitePage":{}}},
    },{
      plugin: require('/home/vsts/work/1/s/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
